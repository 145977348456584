import type { AxiosError } from 'axios';
import { CreatorPost } from 'backend';
import { createMutation } from 'react-query-kit';

import { supabase } from '../../supabase-client';
import { queryClient } from '../common';

type Variables = Partial<CreatorPost> & {
  id: string;
};
type Response = void;

export const useUpdatePost = createMutation<Response, Variables, AxiosError>({
  mutationFn: async (variables) => {
    const { id, ...updateData } = variables;

    const { error } = await supabase
      .from('creatorPosts')
      .update(updateData)
      .eq('id', id);

    if (error) throw error;

    // Invalidate with exact query key structure
    queryClient.invalidateQueries({ queryKey: ['posts'] });
  },
  mutationKey: ['update-post'],
});
