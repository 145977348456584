import type { AxiosError } from 'axios';
import type { Creator } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = Creator[];
type Variables = { campaignId: string };

export const useCampaignCreators = createQuery<Response, Variables, AxiosError>(
  {
    queryKey: ['campaign-creators', ({ campaignId }: Variables) => campaignId],
    fetcher: async ({ campaignId }: Variables) => {
      if (!campaignId) throw new Error('Campaign ID is required');

      const { data, error } = await supabase
        .from('creators')
        .select('*')
        .contains('campaignIds', [campaignId]);

      if (error) throw error;
      return data || [];
    },
  },
);
