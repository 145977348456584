import type { AxiosError } from 'axios';
import type { CreatorAccount } from 'backend';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { client } from '../common';

type Variables = {
  accounts: {
    accountUrl: string;
  }[];
  organizationId: string;
  campaignId: string;
};
type Response = CreatorAccount[];

export const useSubmitAccounts = createMutation<
  Response,
  Variables,
  AxiosError
>({
  mutationFn: async (variables) =>
    client({
      url: '/creators/accounts',
      method: 'POST',
      data: variables,
    }).then((response) => response.data),
});

export const submitAccountInputSchema = z.object({
  accountUrl: z.string().url('Invalid URL'),
});
