import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

interface UseOrganizationImageOptions {
  organizationId?: string | null;
}

type Response = string | null;
type Variables = UseOrganizationImageOptions;

export const useOrganizationImage = createQuery<
  Response,
  Variables,
  AxiosError
>({
  queryKey: ['organization-image'],
  fetcher: async ({ organizationId }) => {
    if (!organizationId) {
      return null;
    }

    const { data } = await supabase.storage
      .from('organizations')
      .getPublicUrl(organizationId);

    return `${data.publicUrl}?t=${new Date().toISOString()}`;
  },
});
