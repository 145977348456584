import type { AxiosError } from 'axios';
import { CreatorPost } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = CreatorPost[];
type Variables = { uid?: string };

export const usePosts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['posts'],
  fetcher: async ({ uid } = {}) => {
    if (!uid) {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');
      uid = user.id;
    }

    const { data, error } = await supabase
      .from('creatorPosts')
      .select('*')
      .eq('uid', uid)
      .order('postedAt', { ascending: false });
    if (error) throw error;
    return data;
  },
});
