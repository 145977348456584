import type { AxiosError } from 'axios';
import type { OrganizationUser } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = OrganizationUser[];
type Variables = { organizationId: string };

export const useOrganizationMembers = createQuery<
  Response,
  Variables,
  AxiosError
>({
  queryKey: ['organization-members'],
  fetcher: async ({ organizationId }) => {
    const { data, error } = await supabase
      .from('organizationUsers')
      .select('*')
      .eq('organizationId', organizationId)
      .order('createdAt', { ascending: false });
    if (error) throw error;
    console.log(data);
    return data;
  },
});
