import { AxiosError } from 'axios';
import { Creator, OrganizationUser } from 'backend';
import posthog from 'posthog-js';
import { configureAuth } from 'react-query-auth';
import { createMutation } from 'react-query-kit';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { useStore } from '@/core';
import { supabase } from '@/supabase-client';
import { useNotifications } from '@/ui/notifications';
import { Spinner } from '@/ui/spinner';

import { useCampaign } from '../campaigns';
import { useOrganization } from '../organizations/use-organization';

import { client } from './client';
import { generateSpecialCode } from './utils';

const getUser = async (): Promise<Creator | OrganizationUser> => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  if (!user) throw new Error('User not found');

  const isCreator = user.user_metadata.type === 'creator';
  let userData: Creator | OrganizationUser | null = null;

  if (!isCreator) {
    const { data: organizationUser } = await supabase
      .from('organizationUsers')
      .select('*')
      .eq('uid', user.id)
      .single();

    userData = organizationUser;
  } else {
    const { data: creator } = await supabase
      .from('creators')
      .select('*')
      .eq('uid', user.id)
      .single();

    userData = creator;
  }
  console.log(userData);
  if (!userData) throw new Error('User not found');

  useStore.getState().setUser(userData);
  return userData;
};

const loginWithEmailAndPassword = async (
  data: LoginInput,
  type: 'creator' | 'organization' | 'orgUser',
): Promise<Creator | OrganizationUser> => {
  const { email, password } = data;
  const {
    data: { user },
    error,
  } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) throw error;

  if (type === 'creator') {
    const { data: creator } = await supabase
      .from('creators')
      .select('*')
      .eq('uid', user?.id || '')
      .single();
    if (!creator) throw new Error('Creator not found');

    return creator;
  } else if (type === 'organization' || type === 'orgUser') {
    const { data: organizationUser } = await supabase
      .from('organizationUsers')
      .select('*')
      .eq('uid', user?.id || '')
      .single();
    if (!organizationUser) {
      const { data: creator } = await supabase
        .from('creators')
        .select('*')
        .eq('uid', user?.id || '')
        .single();
      if (!creator) throw new Error('User not found');
      return creator;
    }
    return organizationUser;
  }

  throw new Error('Invalid user type');
};

const registerCreator = async (
  data: RegisterCreatorInput,
): Promise<Creator> => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    payoutMethod,
    payoutEmail,
    country,
    organizationId,
    campaignId,
  } = data;

  const {
    data: { user },
    error,
  } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        type: 'creator',
        organizationId,
      },
    },
  });

  if (error) throw error;

  const specialCode = 'special-' + generateSpecialCode();

  const creator: Creator = {
    firstName,
    lastName,
    phoneNumber,
    email,
    payoutMethod,
    payoutEmail,
    status: 'PENDING',
    uid: user?.id || '',
    country,
    specialCode,
    organizationId,
    campaignIds: [campaignId],
    hasCompletedOnboarding: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  const { error: insertError } = await supabase
    .from('creators')
    .insert(creator);

  if (insertError) throw insertError;

  return creator;
};

const registerOrganization = async (
  data: RegisterOrganizationInput,
): Promise<OrganizationUser> => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    name, // organization name
  } = data;

  const organizationId = uuidv4();

  const {
    data: { user },
    error,
  } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        type: 'organizationUser',
        organizationId,
      },
    },
  });

  if (error) throw error;

  // Create organization record
  const organizationData = {
    name,
    slug: name.toLowerCase().replace(/\s+/g, '-'), // Generate slug from name
    status: 'active',
    ownerId: user?.id || '',
    stripeAccountId: '', // This should be set up properly with Stripe
    id: organizationId,
  };

  const { error: orgError } = await supabase
    .from('organizations')
    .insert(organizationData)
    .select()
    .single();

  // Create user record
  const userData = {
    uid: user?.id || '',
    email,
    firstName,
    lastName,
    phoneNumber,
    role: 'owner',
    status: 'active',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    organizationId,
  };

  const { error: userError } = await supabase
    .from('organizationUsers')
    .insert(userData);

  if (userError) throw userError;

  if (orgError) throw orgError;

  return userData;
};

const registerOrganizationMember = async (
  data: RegisterOrganizationMemberInput,
): Promise<OrganizationUser> => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    organizationId,
    role,
  } = data;

  // Create auth user
  const {
    data: { user },
    error,
  } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        type: 'organizationUser',
        organizationId,
      },
    },
  });

  if (error) throw error;

  // Create user record
  const userData = {
    uid: user?.id || '',
    email,
    firstName,
    lastName,
    phoneNumber,
    role: role || 'member',
    status: 'active',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    organizationId,
  };
  console.log(userData);
  const { error: userError } = await supabase
    .from('organizationUsers')
    .insert(userData);

  if (userError) throw userError;

  return userData;
};

const logout = async (): Promise<void> => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

export const loginInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
  password: z.string().min(5, 'Required'),
});

export type LoginInput = z.infer<typeof loginInputSchema> & {
  type: 'creator' | 'organization' | 'orgUser';
};

export const registerCreatorInputSchema = z
  .object({
    email: z.string().min(1, 'Required').email('Invalid email'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    phoneNumber: z.string().min(1, 'Required'),
    password: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
    payoutMethod: z.enum(['wire', 'paypal']),
    payoutEmail: z.string().min(1, 'Required').email('Invalid email'),
    country: z.string().min(1, 'Required'),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword'],
      });
    }
  });

export const registerOrganizationInputSchema = z
  .object({
    name: z.string().min(1, 'Required'),
    email: z.string().min(1, 'Required').email('Invalid email'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    phoneNumber: z.string().min(1, 'Required'),
    password: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword'],
      });
    }
  });

export const registerOrganizationMemberInputSchema = z
  .object({
    email: z.string().min(1, 'Required').email('Invalid email'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    phoneNumber: z.string().min(1, 'Required'),
    password: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 6 characters'),
    role: z.enum(['admin', 'member']).optional(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword'],
      });
    }
  });

export type RegisterCreatorInput = z.infer<
  typeof registerCreatorInputSchema
> & {
  type: 'creator';
  organizationId: string;
  campaignId: string;
};
export type RegisterOrganizationInput = z.infer<
  typeof registerOrganizationInputSchema
> & {
  type: 'organization';
};
export type RegisterOrganizationMemberInput = z.infer<
  typeof registerOrganizationMemberInputSchema
> & {
  type: 'orgUser';
  organizationId: string;
};

const authConfig = {
  userFn: getUser,
  loginFn: async (data: LoginInput) => {
    try {
      switch (data.type) {
        case 'creator': {
          const creator = await loginWithEmailAndPassword(data, 'creator');
          posthog.identify(creator.uid, {
            email: creator.email,
            name: `${creator.firstName} ${creator.lastName}`,
          });
          return creator;
        }
        case 'organization': {
          const organization = await loginWithEmailAndPassword(
            data,
            'organization',
          );
          posthog.identify(organization.uid, {
            email: organization.email,
            name: `${organization.firstName} ${organization.lastName}`,
          });
          return organization;
        }
        case 'orgUser': {
          const orgUser = await loginWithEmailAndPassword(data, 'orgUser');
          posthog.identify(orgUser.uid, {
            email: orgUser.email,
            name: `${orgUser.firstName} ${orgUser.lastName}`,
          });
          return orgUser;
        }
      }
    } catch (error) {
      const axiosError = error as any;
      const message = axiosError.response?.data?.message || axiosError.message;
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: typeof message === 'string' ? message : 'An error occurred',
      });
      throw error;
    }
  },
  registerFn: async (
    data:
      | RegisterCreatorInput
      | RegisterOrganizationInput
      | RegisterOrganizationMemberInput,
  ) => {
    try {
      const { type } = data;

      switch (type) {
        case 'creator': {
          const creator = await registerCreator(data as RegisterCreatorInput);
          await client({
            url: '/creators/completeRegistration',
            method: 'POST',
            data: {
              uid: creator.uid,
            },
          });
          posthog.identify(creator.uid, {
            email: creator.email,
            name: `${creator.firstName} ${creator.lastName}`,
          });
          return creator;
        }
        case 'organization': {
          const organization = await registerOrganization(
            data as RegisterOrganizationInput,
          );
          posthog.identify(organization.uid, {
            email: organization.email,
            name: `${organization.firstName} ${organization.lastName}`,
          });
          return organization;
        }
        case 'orgUser': {
          const orgUser = await registerOrganizationMember(
            data as RegisterOrganizationMemberInput,
          );
          posthog.identify(orgUser.uid, {
            email: orgUser.email,
            name: `${orgUser.firstName} ${orgUser.lastName}`,
          });
          return orgUser;
        }
      }
    } catch (error) {
      const axiosError = error as any;
      const message = axiosError.response?.data?.message || axiosError.message;
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: typeof message === 'string' ? message : 'An error occurred',
      });
      throw error;
    }
  },
  logoutFn: logout,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } =
  configureAuth(authConfig);

export const ProtectedRoute = ({
  children,
  requireOnboarding = true,
}: {
  children: React.ReactNode;
  requireOnboarding?: boolean;
}) => {
  const { orgSlug, campaignId } = useParams();
  const { data: user, isLoading: userLoading } = useUser();
  const { data: organization, isLoading: organizationLoading } =
    useOrganization({
      variables: { id: user?.organizationId || '' },
      enabled: !!user?.organizationId,
    });
  const { data: campaign } = useCampaign({
    variables: { id: campaignId ?? '' },
    enabled: !!campaignId,
  });
  const castedCampaign = campaign as any;

  const location = useLocation();

  if (userLoading) return <Spinner />;

  const isCreator = location.pathname.split('/')[1] === 'creator';
  const baseUrl = isCreator
    ? `/creator/${orgSlug}/${campaignId}`
    : '/organization';
  if (!user) {
    return (
      <Navigate
        to={`${baseUrl}/auth/login?redirectTo=${encodeURIComponent(
          location.pathname,
        )}`}
        replace
      />
    );
  }

  if (!isCreator) return children;

  const creator = user as Creator;
  if (organizationLoading) {
    return <Spinner />;
  }

  if (creator.status === 'PENDING') {
    return (
      <Navigate
        to={`/creator/${organization?.slug}/${creator.campaignIds?.[0]}/auth/pending`}
        replace
      />
    );
  }

  if (creator && requireOnboarding) {
    if (creator.hasCompletedOnboarding === false) {
      return (
        <Navigate
          to={`/creator/${organization?.slug}/${campaignId}/onboarding/${castedCampaign.onboardingSteps?.steps?.[0].id}`}
          replace
        />
      );
    }
  } else {
    if (creator.hasCompletedOnboarding === true) {
      return (
        <Navigate
          to={`/creator/${organization?.slug}/${creator.campaignIds?.[0]}/app`}
          replace
        />
      );
    }
  }

  return children;
};
export const useForgotPassword = createMutation<
  void,
  ForgotPasswordInput,
  AxiosError
>({
  mutationFn: async (data: ForgotPasswordInput) => {
    const { error } = await supabase.auth.resetPasswordForEmail(data.email, {
      redirectTo: `${window.location.origin}/auth/reset-password`,
    });
    if (error) throw error;
  },
});

export const forgotPasswordInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
});

export type ForgotPasswordInput = z.infer<typeof forgotPasswordInputSchema>;
