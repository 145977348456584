import type { AxiosError } from 'axios';
import type { CreatorPostEarning } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostEarning[];
type Variables = { uid?: string };

export const useAllEarnings = createQuery<Response, Variables, AxiosError>({
  queryKey: ['all-post-earnings'],
  fetcher: async ({ uid } = {}) => {
    if (!uid) {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');
      uid = user.id;
    }

    const { data, error } = await supabase
      .from('creatorPosts')
      .select(
        `
        creatorPostEarnings!inner (*)
      `,
      )
      .eq('uid', uid);

    if (error) throw error;
    return data?.flatMap((post) => post.creatorPostEarnings) || [];
  },
});
