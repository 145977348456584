import type { AxiosError } from 'axios';
import type { Creator } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = Creator[];
type Variables = void;

export const useCreators = createQuery<Response, Variables, AxiosError>({
  queryKey: ['creators'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase.from('creators').select('*');

    if (error) throw error;
    return data;
  },
});
