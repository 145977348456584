import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

interface UseUserImageOptions {
  userId?: string | null;
}

type Response = string | null;
type Variables = UseUserImageOptions;

export const useUserImage = createQuery<Response, Variables, AxiosError>({
  queryKey: ['user-image'],
  fetcher: async ({ userId }) => {
    if (!userId) {
      return null;
    }
    const { data } = await supabase.storage.from('users').getPublicUrl(userId);

    return `${data.publicUrl}?t=${new Date().toISOString()}`;
  },
});
