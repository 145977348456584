import type { AxiosError } from 'axios';
import type { CreatorPost } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPost;
type Variables = { id: string };

export const usePost = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post'],
  fetcher: async ({ id }) => {
    const { data, error } = await supabase
      .from('creatorPosts')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  },
});
