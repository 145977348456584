import type { AxiosError } from 'axios';
import { Organization } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = Organization;
type Variables = { slug?: string; id?: string };

export const useOrganization = createQuery<Response, Variables, AxiosError>({
  queryKey: ['organization'],
  fetcher: async ({ slug, id }) => {
    if (!slug && !id) {
      throw new Error('Either slug or id must be provided');
    }

    const query = supabase.from('organizations').select('*');
    if (slug) {
      query.eq('slug', slug);
    } else if (id) {
      query.eq('id', id);
    }

    const { data, error } = await query.single();

    if (error) throw error;
    if (!data) throw new Error('Organization not found');

    return data;
  },
});
