import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import { APIProvider } from '@/api';
import { MainErrorFallback } from '@/components/error';
import { Notifications } from '@/ui/notifications';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={MainErrorFallback}>
      <HelmetProvider>
        <APIProvider>
          {import.meta.env.DEV && <ReactQueryDevtools />}
          <Notifications />
          {children}
        </APIProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
