import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { supabase } from '../../supabase-client';

type Variables = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
};
type Response = void;

export const useUpdateProfile = createMutation<Response, Variables, AxiosError>(
  {
    mutationFn: async (variables) => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      if (variables.email) {
        const { error: updateEmailError } = await supabase.auth.updateUser({
          email: variables.email,
        });
        if (updateEmailError) throw updateEmailError;
      }

      const { error } = await supabase
        .from('creators')
        .update(variables)
        .eq('uid', user.id);

      if (error) throw error;
    },
  },
);

export const updateProfileInputSchema = z.object({
  country: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required').email('Invalid email'),
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  phoneNumber: z.string().min(1, 'Required'),
});
