import { useMemo } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { ProtectedRoute } from '../api/common/auth';

import { AppRoot } from './routes/creator/root';
export const createAppRouter = () =>
  createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/organization/auth/login" replace />,
    },
    {
      path: '/creator/:orgSlug/:campaignId',
      children: [
        {
          path: 'auth/forgot-password',
          lazy: async () => {
            const { ForgotPassRoute } = await import(
              './routes/auth/forgot-password'
            );
            return { Component: ForgotPassRoute };
          },
        },
        {
          path: 'auth/register',
          lazy: async () => {
            const { RegisterCreatorRoute } = await import(
              './routes/auth/register-creator'
            );
            return { Component: RegisterCreatorRoute };
          },
        },
        {
          path: 'auth/account',
          lazy: async () => {
            const { AddAccountRoute } = await import('./routes/auth/account');
            return { Component: AddAccountRoute };
          },
        },
        {
          path: 'auth/login',
          lazy: async () => {
            const { LoginRoute } = await import('./routes/auth/login');
            return { Component: LoginRoute };
          },
        },
        {
          path: 'auth/success',
          lazy: async () => {
            const { SuccessRoute } = await import('./routes/auth/success');
            return { Component: SuccessRoute };
          },
        },
        {
          path: 'auth/pending',
          lazy: async () => {
            const { PendingRoute } = await import('./routes/auth/pending');
            return { Component: PendingRoute };
          },
        },
        {
          path: 'app',
          element: (
            <ProtectedRoute requireOnboarding={true}>
              <AppRoot />
            </ProtectedRoute>
          ),
          children: [
            {
              path: '',
              lazy: async () => {
                const { DashboardRoute } = await import(
                  './routes/creator/dashboard'
                );
                return { Component: DashboardRoute };
              },
            },
            {
              path: 'posts',
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { PostsRoute } = await import(
                      './routes/creator/posts/posts'
                    );
                    return { Component: PostsRoute };
                  },
                },
                {
                  path: ':id',
                  lazy: async () => {
                    const { PostRoute } = await import(
                      './routes/creator/posts/post'
                    );
                    return { Component: PostRoute };
                  },
                },
              ],
            },
            {
              path: 'payouts',
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { PayoutsRoute } = await import(
                      './routes/creator/payouts/payouts'
                    );
                    return { Component: PayoutsRoute };
                  },
                },
                {
                  path: ':id',
                  lazy: async () => {
                    const { PayoutRoute } = await import(
                      './routes/creator/payouts/payout'
                    );
                    return { Component: PayoutRoute };
                  },
                },
              ],
            },
            {
              path: 'accounts',
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { AccountsRoute } = await import(
                      './routes/creator/accounts/accounts'
                    );
                    return { Component: AccountsRoute };
                  },
                },
              ],
            },
            {
              path: 'settings',
              lazy: async () => {
                const { SettingsLayout } = await import(
                  './routes/creator/settings/layout'
                );
                return { Component: SettingsLayout };
              },
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { ProfileSettingsRoute } = await import(
                      './routes/creator/settings/profile'
                    );
                    return { Component: ProfileSettingsRoute };
                  },
                },
                {
                  path: 'payout',
                  lazy: async () => {
                    const { PayoutSettingsRoute } = await import(
                      './routes/creator/settings/payout'
                    );
                    return { Component: PayoutSettingsRoute };
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'onboarding',
          children: [
            {
              path: '',
              lazy: async () => {
                const { OnboardingIndexRoute } = await import(
                  './routes/onboarding/index'
                );
                return { Component: OnboardingIndexRoute };
              },
            },
            {
              path: ':stepId',
              lazy: async () => {
                const { OnboardingStepRoute } = await import(
                  './routes/onboarding/onboarding-step'
                );
                return { Component: OnboardingStepRoute };
              },
            },
          ],
        },
      ],
    },
    {
      path: 'organization',
      children: [
        {
          path: 'auth/forgot-password',
          lazy: async () => {
            const { ForgotPassRoute } = await import(
              './routes/auth/forgot-password'
            );
            return { Component: ForgotPassRoute };
          },
        },
        {
          path: 'auth/register',
          lazy: async () => {
            const { RegisterOrganizationRoute } = await import(
              './routes/auth/register-organization'
            );
            return { Component: RegisterOrganizationRoute };
          },
        },
        {
          path: 'auth/login',
          lazy: async () => {
            const { LoginRoute } = await import('./routes/auth/login');
            return { Component: LoginRoute };
          },
        },
        {
          path: 'auth/join',
          lazy: async () => {
            const { JoinOrganizationRoute } = await import(
              './routes/auth/join-organization'
            );
            return { Component: JoinOrganizationRoute };
          },
        },
        {
          path: 'app',
          element: (
            <ProtectedRoute requireOnboarding={false}>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            {
              path: 'slack',
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { SlackRoute } = await import(
                      './routes/organization/slack'
                    );
                    return { Component: SlackRoute };
                  },
                },
                {
                  path: 'callback',
                  lazy: async () => {
                    const { SlackCallbackRoute } = await import(
                      './routes/organization/slack-callback'
                    );
                    return { Component: SlackCallbackRoute };
                  },
                },
              ],
            },
            {
              path: 'settings',
              lazy: async () => {
                const { SettingsLayout } = await import(
                  './routes/organization/settings/layout'
                );
                return { Component: SettingsLayout };
              },
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { ProfileSettingsRoute } = await import(
                      './routes/organization/settings/profile'
                    );
                    return { Component: ProfileSettingsRoute };
                  },
                },
                {
                  path: 'organization',
                  lazy: async () => {
                    const { OrganizationSettingsRoute } = await import(
                      './routes/organization/settings/organization'
                    );
                    return { Component: OrganizationSettingsRoute };
                  },
                },
                {
                  path: 'members',
                  lazy: async () => {
                    const { OrganizationMembersRoute } = await import(
                      './routes/organization/settings/members'
                    );
                    return { Component: OrganizationMembersRoute };
                  },
                },
              ],
            },
            {
              path: 'campaigns',
              children: [
                {
                  path: '',
                  lazy: async () => {
                    const { OrganizationRoute } = await import(
                      './routes/organization/campaigns/campaigns'
                    );
                    return { Component: OrganizationRoute };
                  },
                },
                {
                  path: ':campaignId',
                  element: <AppRoot />,
                  children: [
                    {
                      path: '',
                      lazy: async () => {
                        const { CampaignRoute } = await import(
                          './routes/organization/campaigns/campaign-dashboard/campaign'
                        );
                        return { Component: CampaignRoute };
                      },
                    },
                    {
                      path: 'posts',
                      children: [
                        {
                          path: '',
                          lazy: async () => {
                            const { PostsRoute } = await import(
                              './routes/organization/campaigns/campaign-dashboard/posts'
                            );
                            return { Component: PostsRoute };
                          },
                        },
                        {
                          path: ':id',
                          lazy: async () => {
                            const { PostRoute } = await import(
                              './routes/organization/campaigns/campaign-dashboard/post'
                            );
                            return { Component: PostRoute };
                          },
                        },
                      ],
                    },
                    {
                      path: 'creators',
                      children: [
                        {
                          path: '',
                          lazy: async () => {
                            const { CreatorsRoute } = await import(
                              './routes/organization/campaigns/campaign-dashboard/creators/creators'
                            );
                            return { Component: CreatorsRoute };
                          },
                        },
                        {
                          path: ':id',
                          lazy: async () => {
                            const { CreatorRoute } = await import(
                              './routes/organization/campaigns/campaign-dashboard/creators/creator'
                            );
                            return { Component: CreatorRoute };
                          },
                        },
                      ],
                    },
                    {
                      path: 'payouts',
                      lazy: async () => {
                        const { PayoutsRoute } = await import(
                          './routes/organization/campaigns/campaign-dashboard/payouts'
                        );
                        return { Component: PayoutsRoute };
                      },
                    },
                    {
                      path: 'reports',
                      lazy: async () => {
                        const { ReportsRoute } = await import(
                          './routes/organization/campaigns/campaign-dashboard/reports/reports'
                        );
                        return { Component: ReportsRoute };
                      },
                    },
                    {
                      path: 'settings',
                      lazy: async () => {
                        const { CampaignSettingsLayout } = await import(
                          './routes/organization/campaigns/campaign-dashboard/settings/layout'
                        );
                        return { Component: CampaignSettingsLayout };
                      },
                      children: [
                        {
                          path: '',
                          lazy: async () => {
                            const { CampaignOverviewSettings } = await import(
                              './routes/organization/campaigns/campaign-dashboard/settings/overview'
                            );
                            return { Component: CampaignOverviewSettings };
                          },
                        },
                        {
                          path: 'payout',
                          lazy: async () => {
                            const { CampaignPayoutSettings } = await import(
                              './routes/organization/campaigns/campaign-dashboard/settings/payout'
                            );
                            return { Component: CampaignPayoutSettings };
                          },
                        },
                        {
                          path: 'onboarding',
                          lazy: async () => {
                            const { CampaignOnboardingSettings } = await import(
                              './routes/organization/campaigns/campaign-dashboard/settings/onboarding'
                            );
                            return { Component: CampaignOnboardingSettings };
                          },
                        },
                        {
                          path: 'analysis',
                          lazy: async () => {
                            const { CampaignAnalysisSettings } = await import(
                              './routes/organization/campaigns/campaign-dashboard/settings/analysis'
                            );
                            return { Component: CampaignAnalysisSettings };
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      lazy: async () => {
        const { NotFoundRoute } = await import('./routes/not-found');
        return { Component: NotFoundRoute };
      },
    },
  ]);

export const AppRouter = () => {
  const router = useMemo(() => createAppRouter(), []);

  return <RouterProvider router={router} />;
};
