import type { AxiosError } from 'axios';
import type { OrganizationUser } from 'backend';
import { createMutation } from 'react-query-kit';

import { queryClient } from '../common/api-provider';
import { client } from '../common/client';

type Variables = {
  email: string;
  organizationId: string;
};

type Response = {
  newUser: OrganizationUser;
};

export const useInviteMember = createMutation<Response, Variables, AxiosError>({
  mutationFn: async ({ organizationId, email }) => {
    const { data } = await client({
      url: `/organizations/${organizationId}/invite`,
      method: 'POST',
      data: { email },
    });
    queryClient.invalidateQueries({ queryKey: ['organizationMembers'] });
    return data;
  },
});
