'use client';

import { Check, ChevronsUpDown, PlusCircle } from 'lucide-react';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useCampaignImage,
  useCampaigns,
  useOrganization,
  useOrganizationImage,
} from '@/api';
import { CreateCampaignForm } from '@/components/create-campaign-form';
import { useStore } from '@/core';
import { Avatar, AvatarFallback, AvatarImage } from '@/ui/avatar';
import { Button } from '@/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/ui/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/ui/dialog';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/popover';
import { cn } from '@/utils';

interface CampaignAvatarProps {
  campaignId: string;
  name: string;
}

function CampaignAvatar({ campaignId, name }: CampaignAvatarProps) {
  const { data: campaignImage } = useCampaignImage({
    variables: { campaignId },
    enabled: !!campaignId,
  });

  return (
    <Avatar className="mr-2 h-5 w-5 flex-shrink-0">
      <AvatarImage src={campaignImage || ''} alt={name} />
      <AvatarFallback>
        {name
          .split(' ')
          .map((word) => word[0])
          .join('')}
      </AvatarFallback>
    </Avatar>
  );
}

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface CampaignSwitcherProps extends PopoverTriggerProps {}

interface Campaign {
  label: string;
  value: string;
}

interface GroupType {
  label: string;
  campaigns: Campaign[];
}

export default function CampaignSwitcher({ className }: CampaignSwitcherProps) {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { user } = useStore();
  const [selectedCampaign, setSelectedCampaign] = React.useState<Campaign>({
    label: '',
    value: '',
  });
  const { data: campaigns } = useCampaigns({
    variables: { organizationId: user?.organizationId ?? '' },
  });
  const { data: organization } = useOrganization({
    variables: { id: user?.organizationId ?? '' },
  });
  const { data: organizationImage } = useOrganizationImage({
    variables: { organizationId: organization?.id },
    enabled: !!organization?.id,
  });
  const { data: selectedCampaignImage } = useCampaignImage({
    variables: { campaignId: selectedCampaign?.value },
    enabled:
      !!selectedCampaign?.value && selectedCampaign.value !== organization?.id,
  });
  const [open, setOpen] = React.useState(false);
  const [showNewCampaignDialog, setShowNewCampaignDialog] =
    React.useState(false);

  const groups: GroupType[] = [
    {
      label: 'Organization',
      campaigns: [
        {
          label: organization?.name ?? '',
          value: organization?.id ?? '',
        },
      ],
    },
    {
      label: 'Campaigns',
      campaigns:
        campaigns?.map((campaign) => ({
          label: campaign.name,
          value: campaign.id,
        })) ?? [],
    },
  ];

  // Update selected campaign when URL or campaigns data changes
  React.useEffect(() => {
    if (campaigns && campaignId) {
      const campaign = campaigns.find((c) => c.id === campaignId);
      if (campaign) {
        setSelectedCampaign({ label: campaign.name, value: campaign.id });
      }
    } else if (organization && (!campaignId || !campaigns?.length)) {
      // If no campaign is selected or no campaigns exist, default to organization
      setSelectedCampaign({
        label: organization.name,
        value: organization.id,
      });
    }
  }, [campaigns, campaignId, organization]);

  // Navigate when campaign is selected
  const handleCampaignSelect = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setOpen(false);
    if (campaign.value !== organization?.id) {
      navigate(`/organization/app/campaigns/${campaign.value}`);
    } else {
      navigate('/organization/app/campaigns');
    }
  };

  return (
    <Dialog
      open={showNewCampaignDialog}
      onOpenChange={setShowNewCampaignDialog}
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a campaign"
            className={cn('w-[200px] justify-between', className)}
          >
            <Avatar className="mr-2 h-5 w-5 flex-shrink-0">
              <AvatarImage
                src={selectedCampaignImage || organizationImage || ''}
                alt={selectedCampaign.label}
              />
              <AvatarFallback>
                {selectedCampaign.label
                  .split(' ')
                  .map((word) => word[0])
                  .join('')}
              </AvatarFallback>
            </Avatar>
            <span className="truncate flex-1 text-left">
              {selectedCampaign.label}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 flex-shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search campaign..." />
            <CommandList>
              <CommandEmpty>No campaign found.</CommandEmpty>
              {groups.map((group) => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.campaigns?.map((campaign) => (
                    <CommandItem
                      key={campaign.value}
                      onSelect={() => handleCampaignSelect(campaign)}
                      className="text-sm flex items-center"
                    >
                      {campaign.value === organization?.id ? (
                        <Avatar className="mr-2 h-5 w-5 flex-shrink-0">
                          <AvatarImage
                            src={organizationImage || ''}
                            alt={campaign.label}
                          />
                          <AvatarFallback>
                            {campaign.label
                              .split(' ')
                              .map((word) => word[0])
                              .join('')}
                          </AvatarFallback>
                        </Avatar>
                      ) : (
                        <CampaignAvatar
                          campaignId={campaign.value}
                          name={campaign.label}
                        />
                      )}
                      <span className="truncate flex-1">{campaign.label}</span>
                      <Check
                        className={cn(
                          'ml-2 h-4 w-4 flex-shrink-0',
                          selectedCampaign.value === campaign.value
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewCampaignDialog(true);
                    }}
                  >
                    <PlusCircle className="h-5 w-5" />
                    Create Campaign
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Campaign</DialogTitle>
          <DialogDescription>
            Create a new campaign to start working with creators.
          </DialogDescription>
        </DialogHeader>
        <CreateCampaignForm onSuccess={() => setShowNewCampaignDialog(false)} />
      </DialogContent>
    </Dialog>
  );
}
