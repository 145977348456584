import type { AxiosError } from 'axios';
import type { CreatorAccount, CreatorAccountCompensation } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = (CreatorAccount & {
  compensation: CreatorAccountCompensation | null;
})[];
type Variables = null;

export const useOrgAccounts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['org-accounts'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // Get user's organization
    const { data: orgUser, error: orgError } = await supabase
      .from('organizationUsers')
      .select('organizationId')
      .eq('uid', user.id)
      .single();

    if (orgError) throw orgError;
    if (!orgUser) throw new Error('User not part of any organization');

    // Get all accounts under the organization's campaigns
    const { data: accounts, error: accountsError } = await supabase
      .from('creatorAccounts')
      .select(
        `
        *,
        campaign:campaignId (
          id,
          organizationId
        ),
        compensation:creatorAccountCompensations(
          *
        )
      `,
      )
      .eq('campaign.organizationId', orgUser.organizationId);

    if (accountsError) throw accountsError;

    return (accounts || []).map((account) => ({
      ...account,
      compensation:
        account.compensation?.[account.compensation.length - 1] || null,
    }));
  },
});
