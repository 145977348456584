import { Campaign } from 'backend';

import {
  queryClient,
  submitCampaignInputSchema,
  useSubmitCampaign,
} from '@/api';
import { Button } from '@/ui/button';
import { DialogClose } from '@/ui/dialog';
import { Form, Input, Textarea } from '@/ui/form';
import { useNotifications } from '@/ui/notifications';

interface CreateCampaignFormProps {
  onSuccess?: () => void;
}

export const CreateCampaignForm = ({ onSuccess }: CreateCampaignFormProps) => {
  const { addNotification } = useNotifications();
  const submitting = useSubmitCampaign({
    onSuccess: (campaign: Campaign) => {
      if (campaign) {
        queryClient.invalidateQueries({ queryKey: ['campaigns'] });
        addNotification({
          type: 'success',
          title: 'Campaign Created',
        });
        onSuccess?.();
      }
    },
  });

  return (
    <Form
      onSubmit={(values) => {
        console.log(values);
        submitting.mutate(values);
      }}
      schema={submitCampaignInputSchema}
      options={{
        shouldUnregister: true,
      }}
    >
      {({ register, formState }) => (
        <div className="space-y-4">
          <Input
            type="text"
            label="Campaign Name"
            error={formState.errors['name']}
            registration={register('name')}
          />
          <Textarea
            label="Description"
            error={formState.errors['description']}
            registration={register('description')}
          />
          <div className="flex justify-end gap-4">
            <DialogClose asChild>
              <Button type="button" variant="outline">
                Cancel
              </Button>
            </DialogClose>
            <Button isLoading={submitting.isPending} type="submit">
              Create Campaign
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
