import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { supabase } from '@/supabase-client';

import { queryClient } from '../common';

type Variables = {
  id: string;
  status?: 'APPROVED' | 'REJECTED';
  trackAllPosts?: boolean;
  compensation?: {
    amount: number;
    type: string;
    startDate: string;
    endDate: string;
    cpmCutoffInDays: number;
    minimumViews: number;
    monthlyPostGoal: number;
    bonusThresholds: { views: number; bonus: number }[];
    maximumPayout: number;
  };
};

type Response = void;

export const useUpdateAccount = createMutation<Response, Variables>({
  mutationFn: async ({ id, status, compensation, trackAllPosts }) => {
    // If compensation provided, create new compensation record
    if (compensation) {
      const { error: compensationError } = await supabase
        .from('creatorAccountCompensations')
        .insert({
          accountId: id,
          ...compensation,
          startDate: compensation.startDate,
          endDate: compensation.endDate,
          status: 'ACTIVE',
        })
        .select();

      if (compensationError) throw compensationError;
    }

    if (status || compensation || trackAllPosts !== undefined) {
      const updateData: Record<string, any> = {};
      if (status || compensation) {
        updateData.status = compensation ? 'APPROVED' : status;
      }
      if (trackAllPosts !== undefined) {
        updateData.trackAllPosts = trackAllPosts;
      }

      const { error: accountError } = await supabase
        .from('creatorAccounts')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();

      if (accountError) throw accountError;
    }

    queryClient.invalidateQueries({ queryKey: ['accounts'] });
  },
});

export const accountCompensationSchema = z.object({
  amount: z.number().min(0, 'Amount must be greater than 0'),
  type: z.enum(['flat', 'cpm']),
  startDate: z.string(),
  endDate: z.string(),
  cpmCutoffInDays: z.number().min(1),
  minimumViews: z.number().min(0),
  maximumPayout: z.number().min(0),
  monthlyPostGoal: z.number().min(1, 'Monthly post goal must be at least 1'),
  bonusThresholds: z.array(
    z.object({
      views: z.number().min(0),
      bonus: z.number().min(0),
    }),
  ),
});
