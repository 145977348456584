import { createClient } from '@supabase/supabase-js';
import { Database } from 'backend/database.types';

import { env } from './config/env';

export const supabase = createClient<Database>(
  env.SUPABASE_URL,
  env.SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
    },
    db: {
      schema: 'public',
    },
  },
);
