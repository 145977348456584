import type { AxiosError } from 'axios';
import type { CreatorPostEarning } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostEarning[];
type Variables = null;

export const useOrgEarnings = createQuery<Response, Variables, AxiosError>({
  queryKey: ['org-earnings'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // Get user's organization
    const { data: orgUser, error: orgError } = await supabase
      .from('organizationUsers')
      .select('organizationId')
      .eq('uid', user.id)
      .single();

    if (orgError) throw orgError;
    if (!orgUser) throw new Error('User not part of any organization');

    // Get all posts under the organization's campaigns
    const { data: earnings, error: earningsError } = await supabase
      .from('creatorPostEarnings')
      .select(
        `
        *,
        post:postId (
          id,
          organizationId
        )
      `,
      )
      .eq('post.organizationId', orgUser.organizationId);

    if (earningsError) throw earningsError;
    return earnings || [];
  },
});
