import type { AxiosError } from 'axios';
import type { CreatorAccount, CreatorAccountCompensation } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = (CreatorAccount & {
  compensation: CreatorAccountCompensation | null;
})[];

type Variables = { uid?: string };

export const useAccounts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['accounts'],
  fetcher: async ({ uid } = {}) => {
    if (!uid) {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');
      uid = user.id;
    }

    const { data, error } = await supabase
      .from('creatorAccounts')
      .select(
        `
        *,
        compensation:creatorAccountCompensations(
          *
        )
      `,
      )
      .eq('uid', uid)
      .order('createdAt', { ascending: false });
    if (error) throw error;

    return data.map((account) => ({
      ...account,
      compensation:
        account.compensation?.[account.compensation.length - 1] || null,
    }));
  },
});
