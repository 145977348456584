import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import { App } from './app';
import { env } from './config/env';

Sentry.init({
  dsn: 'https://9dd87370d8a6109936ffab191b6ad6c1@o4508270058340352.ingest.us.sentry.io/4508270059651072',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', env.API_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: env.APP_ENV !== 'development',
});

const root = document.getElementById('root');
if (!root) throw new Error('No root element found');

createRoot(root).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={'phc_HTmb546cbcbE9fq2iloMXjo2zdnzomBJgmwkx3LfiDh'}
      options={{
        api_host: 'https://us.i.posthog.com',
      }}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
