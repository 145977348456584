import type { AxiosError } from 'axios';
import { Campaign } from 'backend';
import { createMutation } from 'react-query-kit';

import { queryClient } from '../common';

import { supabase } from '@/supabase-client';

type Variables = Partial<Campaign> & { id: string };
type Response = void;

export const useUpdateCampaign = createMutation<
  Response,
  Variables,
  AxiosError
>({
  mutationFn: async (input) => {
    const { error } = await supabase
      .from('campaigns')
      .update(input)
      .eq('id', input.id);

    if (error) throw error;

    // Invalidate and refetch
    queryClient.invalidateQueries({ queryKey: ['campaign', input.id] });
  },
  mutationKey: ['update-campaign'],
});
