import type { AxiosError } from 'axios';
import type { CreatorPostEarning } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostEarning[];
type Variables = { campaignId: string };

export const useCampaignEarnings = createQuery<Response, Variables, AxiosError>(
  {
    queryKey: ['campaign-earnings'],
    fetcher: async ({ campaignId }) => {
      if (!campaignId) throw new Error('Campaign ID is required');

      // First get all posts for this campaign
      const { data: posts, error: postsError } = await supabase
        .from('creatorPosts')
        .select('id')
        .eq('campaignId', campaignId);

      if (postsError) throw postsError;
      if (!posts?.length) return [];

      // Then get earnings for these posts
      const { data: earnings, error: earningsError } = await supabase
        .from('creatorPostEarnings')
        .select('*')
        .in(
          'postId',
          posts.map((p) => p.id),
        );

      if (earningsError) throw earningsError;
      return earnings || [];
    },
  },
);
