import type { AxiosError } from 'axios';
import type { CreatorPostStat } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostStat[];
type Variables = { campaignId: string };

export const useCampaignStats = createQuery<Response, Variables, AxiosError>({
  queryKey: ['campaign-stats'],
  fetcher: async ({ campaignId }) => {
    if (!campaignId) throw new Error('Campaign ID is required');

    // First get all posts for this campaign
    const { data: posts, error: postsError } = await supabase
      .from('creatorPosts')
      .select('id')
      .eq('campaignId', campaignId);

    if (postsError) throw postsError;
    if (!posts?.length) return [];

    // Then get stats for these posts
    const { data: stats, error: statsError } = await supabase
      .from('creatorPostStats')
      .select('*')
      .in(
        'postId',
        posts.map((p) => p.id),
      );

    if (statsError) throw statsError;
    return stats || [];
  },
});
