import type { AxiosError } from 'axios';
import { CreatorPost } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = CreatorPost[];
type Variables = { campaignId: string };

export const useCampaignPosts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['campaign-posts'],
  fetcher: async ({ campaignId }) => {
    if (!campaignId) throw new Error('Campaign ID is required');

    const { data, error: postsError } = await supabase
      .from('creatorPosts')
      .select(
        `
        *,
        campaign:campaignId (
          id,
          name
        )
      `,
      )
      .eq('campaignId', campaignId);

    if (postsError) throw postsError;
    return data || [];
  },
});
