import type { AxiosError } from 'axios';
import { CreatorPost } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

type Response = CreatorPost[];
type Variables = null;

export const useOrgPosts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['org-posts'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data: orgUser, error: orgError } = await supabase
      .from('organizationUsers')
      .select('organizationId')
      .eq('uid', user.id)
      .single();

    if (orgError) throw orgError;
    if (!orgUser) throw new Error('User not part of any organization');

    const { data: campaigns, error: campaignError } = await supabase
      .from('campaigns')
      .select('id')
      .eq('organizationId', orgUser.organizationId);

    if (campaignError) throw campaignError;
    if (!campaigns?.length) return [];

    const { data, error: postsError } = await supabase
      .from('creatorPosts')
      .select(
        `
        *,
        campaign:campaignId (
          id,
          name
        )
      `,
      )
      .in(
        'campaignId',
        campaigns.map((c) => c.id),
      );

    if (postsError) throw postsError;
    return data || [];
  },
});
