import type { AxiosError } from 'axios';
import type { Campaign } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = Campaign[];
type Variables = { organizationId: string };

export const useCampaigns = createQuery<Response, Variables, AxiosError>({
  queryKey: ['campaigns'],
  fetcher: async ({ organizationId }) => {
    const { data, error } = await supabase
      .from('campaigns')
      .select('*')
      .eq('organizationId', organizationId);

    if (error) throw error;
    return data;
  },
});
