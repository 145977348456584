import { Creator, OrganizationUser } from 'backend';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface GlobalStore {
  // User state
  user: Creator | OrganizationUser | null;
  setUser: (user: Creator | OrganizationUser | null) => void;

  // Organization/Campaign context
  currentOrganization: string | null;
  setCurrentOrganization: (org: string | null) => void;
  currentCampaign: string | null;
  setCurrentCampaign: (campaign: string | null) => void;
}

export const useStore = create<GlobalStore>()(
  devtools(
    (set) => ({
      // User
      user: null,
      setUser: (user) => set({ user }),

      // Organization/Campaign
      currentOrganization: null,
      setCurrentOrganization: (org) => set({ currentOrganization: org }),
      currentCampaign: null,
      setCurrentCampaign: (campaign) => set({ currentCampaign: campaign }),
    }),
    {
      name: 'global-store',
    },
  ),
);
