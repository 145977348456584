import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCampaign } from '@/api';

export const OnboardingIndexRoute = () => {
  const navigate = useNavigate();
  const { campaignId, orgSlug } = useParams();
  const { data: campaign, isLoading } = useCampaign({
    variables: { id: campaignId! },
  });

  useEffect(() => {
    if (!isLoading && !campaignId) {
      navigate('/404', { replace: true });
      return;
    }

    const castedCampaign = campaign as any;

    if (castedCampaign?.onboardingSteps?.steps?.length > 0) {
      const firstStep = castedCampaign.onboardingSteps.steps[0];
      navigate(`/creator/${orgSlug}/${campaignId}/onboarding/${firstStep.id}`, {
        replace: true,
      });
    }
  }, [campaign, navigate, isLoading, campaignId, orgSlug]);

  return null;
};
