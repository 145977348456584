import type { AxiosError } from 'axios';
import type { CreatorPostEarning } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostEarning[];
type Variables = { id: string };

export const usePostEarnings = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post-earnings'],
  fetcher: async ({ id }) => {
    const { data, error } = await supabase
      .from('creatorPostEarnings')
      .select('*')
      .eq('postId', id);

    if (error) throw error;
    return data;
  },
});
