import type { AxiosError } from 'axios';
import { CreatorPost } from 'backend';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { client } from '../common';

type Variables = {
  contentUrl: string;
  campaignId: string;
  organizationId: string;
};
type Response = CreatorPost;

export const useSubmitPost = createMutation<Response, Variables, AxiosError>({
  mutationFn: async (variables) => {
    const { data } = await client({
      url: '/creators/posts',
      method: 'POST',
      data: variables,
    });
    const { post } = data;
    return post;
  },
});

export const submitPostInputSchema = z.object({
  contentUrl: z.string().url(),
});
