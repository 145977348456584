import type { AxiosError } from 'axios';
import type { CreatorPostStat } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostStat[];
type Variables = null;

export const useOrgStats = createQuery<Response, Variables, AxiosError>({
  queryKey: ['org-stats'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // Get user's organization
    const { data: orgUser, error: orgError } = await supabase
      .from('organizationUsers')
      .select('organizationId')
      .eq('uid', user.id)
      .single();

    if (orgError) throw orgError;
    if (!orgUser) throw new Error('User not part of any organization');

    // Get all stats under the organization's posts
    const { data: stats, error: statsError } = await supabase
      .from('creatorPostStats')
      .select(
        `
        *,
        post:postId (
          id,
          organizationId
        )
      `,
      )
      .eq('post.organizationId', orgUser.organizationId);

    if (statsError) throw statsError;
    return stats || [];
  },
});
