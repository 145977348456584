import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { queryClient } from '../common';

import { supabase } from '@/supabase-client';

type Variables = {
  organizationId: string;
  uid: string;
};

type Response = void;

export const useRemoveMember = createMutation<Response, Variables, AxiosError>({
  mutationFn: async ({ organizationId, uid }) => {
    // Update the organization user status to REVOKED
    const { error } = await supabase
      .from('organizationUsers')
      .update({ status: 'REVOKED' })
      .eq('organizationId', organizationId)
      .eq('uid', uid);

    if (error) throw error;

    // Invalidate relevant queries
    queryClient.invalidateQueries({ queryKey: ['organization'] });
  },
  mutationKey: ['remove-member'],
});
