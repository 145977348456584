import type { AxiosError } from 'axios';
import type { CreatorPostStat } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostStat[];
type Variables = { id: string };

export const usePostStats = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post-stats'],
  fetcher: async ({ id }) => {
    const { data, error } = await supabase
      .from('creatorPostStats')
      .select('*')
      .eq('postId', id);

    if (error) throw error;
    return data;
  },
});
