import {
  IconBuilding,
  IconLogout,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useUserImage } from '@/api';
import { useUser, useLogout } from '@/api/common/auth';
import { Avatar, AvatarFallback, AvatarImage } from '@/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/ui/dropdown-menu';
import { Link } from '@/ui/link';

export function UserNav() {
  const { data: user } = useUser();
  const { data: imageUrl } = useUserImage({
    variables: {
      userId: user?.uid,
    },
  });
  const { mutate: logout } = useLogout();
  const location = useLocation();
  const { orgSlug, campaignId } = useParams();
  const isCreator = location.pathname.includes('/creator');
  const [open, setOpen] = useState(false);

  if (!user) return null;

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <button className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage alt={user.email} src={imageUrl ?? ''} />
            <AvatarFallback>
              {user?.firstName?.[0]}
              {user?.lastName?.[0]}
            </AvatarFallback>
          </Avatar>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild onClick={() => setOpen(false)}>
            <Link
              to={
                isCreator
                  ? `/creator/${orgSlug}/${campaignId}/app/settings`
                  : '/organization/app/settings'
              }
              className="cursor-pointer"
            >
              <IconUser className="mr-2 h-4 w-4" />
              Profile
            </Link>
          </DropdownMenuItem>
          {!isCreator && (
            <>
              <DropdownMenuItem asChild onClick={() => setOpen(false)}>
                <Link
                  to="/organization/app/campaigns"
                  className="cursor-pointer"
                >
                  <IconBuilding className="mr-2 h-4 w-4" />
                  Organization
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild onClick={() => setOpen(false)}>
                <Link
                  to="/organization/app/settings/organization"
                  className="cursor-pointer"
                >
                  <IconSettings className="mr-2 h-4 w-4" />
                  Settings
                </Link>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
            logout({});
          }}
        >
          <IconLogout className="mr-2 h-4 w-4" />
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
