import type { AxiosError } from 'axios';
import { Creator } from 'backend';
import { createMutation } from 'react-query-kit';

import { supabase } from '../../supabase-client';
import { queryClient } from '../common';

type Variables = Partial<Creator> & {
  uid?: string;
};
type Response = void;

export const useUpdateCreator = createMutation<Response, Variables, AxiosError>(
  {
    mutationFn: async (variables) => {
      const { uid, ...updateData } = variables;

      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Use provided uid or fall back to current user's id
      const targetUid = uid || user.id;

      if (updateData.email && !uid) {
        const { error: updateEmailError } = await supabase.auth.updateUser({
          email: updateData.email,
        });
        if (updateEmailError) throw updateEmailError;
      }
      const { error } = await supabase
        .from('creators')
        .update(updateData)
        .eq('uid', targetUid);

      if (error) throw error;
      queryClient.invalidateQueries({ queryKey: ['creator'] });
    },
    mutationKey: ['creator'],
  },
);
