import type { AxiosError } from 'axios';
import type { CreatorPostAnalysis } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = CreatorPostAnalysis[];
type Variables = void;

export const usePostAnalyses = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post-analyses'],
  fetcher: async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('creatorPostAnalyses')
      .select('*')
      .eq('uid', user.id);
    console.log(data, 'post analyses');
    if (error) throw error;
    return data;
  },
});
