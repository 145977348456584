import type { AxiosError } from 'axios';
import { Campaign } from 'backend';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { supabase } from '@/supabase-client';

export const submitCampaignInputSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Description is required'),
});

type SubmitCampaignInput = z.infer<typeof submitCampaignInputSchema>;
type Response = Campaign;

export const useSubmitCampaign = createMutation<
  Response,
  SubmitCampaignInput,
  AxiosError
>({
  mutationFn: async (data) => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) throw new Error('User not found');
    const userMetadata = user.user_metadata;

    if (!userMetadata.organizationId) throw new Error('Organization not found');

    const { data: campaign, error } = await supabase
      .from('campaigns')
      .insert({
        ...data,
        platforms: ['instagram', 'tiktok', 'youtube', 'snapchat'],
        organizationId: userMetadata.organizationId,
        status: 'ACTIVE',
        startDate: new Date().toISOString(),
        endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90).toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    return campaign;
  },
});
