import {
  IconChartBar,
  IconCurrencyDollar,
  IconFile,
  IconHome,
  IconSettings,
  IconSlash,
  IconUsers,
} from '@tabler/icons-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import CampaignSwitcher from '../campaign-switcher';
import { UserNav } from '../user-nav';

import { useOrganization, useCampaign } from '@/api';
import logo from '@/assets/logo-dark.png';
import { Spinner } from '@/ui/spinner';
import { Tabs, TabsList, TabsTrigger } from '@/ui/tabs';

type NavigationItem = {
  name: string;
  value: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const Logo = () => {
  return <img src={logo} alt="Logo" className="h-12 w-12" />;
};

export function DashboardLayout({
  children,
  type,
}: {
  children: React.ReactNode;
  type: 'organization' | 'creator';
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgSlug, campaignId } = useParams();

  const isCreator = location.pathname.split('/')[1] === 'creator';
  const { data: organization } = useOrganization({
    variables: { slug: orgSlug },
    enabled: isCreator,
  });
  const { data: campaign } = useCampaign({
    variables: { id: campaignId ?? '' },
    enabled: isCreator,
  });

  const creatorNavigation = [
    { name: 'Dashboard', value: 'dashboard', to: '.', icon: IconHome },
    { name: 'Posts', value: 'posts', to: './posts', icon: IconFile },
    { name: 'Accounts', value: 'accounts', to: './accounts', icon: IconUsers },
    {
      name: 'Payouts',
      value: 'payouts',
      to: './payouts',
      icon: IconCurrencyDollar,
    },
    {
      name: 'Settings',
      value: 'settings',
      to: './settings',
      icon: IconSettings,
    },
  ] as NavigationItem[];

  const organizationNavigation = [
    { name: 'Dashboard', value: 'dashboard', to: '.', icon: IconHome },
    { name: 'Creators', value: 'creators', to: './creators', icon: IconUsers },
    { name: 'Posts', value: 'posts', to: './posts', icon: IconFile },
    {
      name: 'Payouts',
      value: 'payouts',
      to: './payouts',
      icon: IconCurrencyDollar,
    },
    { name: 'Reports', value: 'reports', to: './reports', icon: IconChartBar },
    {
      name: 'Settings',
      value: 'settings',
      to: './settings',
      icon: IconSettings,
    },
  ] as NavigationItem[];

  const navigation =
    type === 'creator' ? creatorNavigation : organizationNavigation;

  // Update the current tab logic to be more precise
  const currentPath = location.pathname;
  const currentTab =
    navigation.find((item) => {
      if (item.value === 'dashboard') {
        return /\/app\/?$/.test(currentPath);
      }
      return currentPath.includes(`/${item.value}`);
    })?.value || 'dashboard';

  if (isCreator && !organization && !campaign) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex-col md:flex bg-white">
        <div className="border-b">
          <div className="flex h-16 items-center px-4">
            <Logo />
            {type === 'creator' ? (
              <div className="ml-3 flex flex-col justify-center">
                <span className="text-xs text-gray-500">
                  {organization?.name}
                </span>
                {campaign && (
                  <span className="font-medium text-sm">
                    {campaign.name} Campaign
                  </span>
                )}
              </div>
            ) : (
              <>
                <IconSlash className="text-primary" />
                <CampaignSwitcher />
              </>
            )}
            <div className="ml-auto flex items-center space-x-4">
              <UserNav />
            </div>
          </div>
          <div className="w-full sm:px-6 lg:px-8 bg-white overflow-x-auto scrollbar-hide [-ms-overflow-style:none] [scrollbar-width:none]">
            <Tabs value={currentTab} className="mx-0 bg-transparent">
              <TabsList className="justify-start w-max min-w-full h-auto">
                {navigation.map((item) => (
                  <TabsTrigger
                    key={item.value}
                    value={item.value}
                    onClick={() => navigate(item.to)}
                    className="flex items-center gap-2 whitespace-nowrap"
                  >
                    <item.icon className="h-4 w-4" />
                    {item.name}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="flex min-h-screen flex-col">{children}</div>
    </>
  );
}
