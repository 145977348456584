import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { supabase } from '@/supabase-client';

interface UseCampaignImageOptions {
  campaignId?: string | null;
}

type Response = string | null;
type Variables = UseCampaignImageOptions;

export const useCampaignImage = createQuery<Response, Variables, AxiosError>({
  queryKey: ['campaign-image'],
  fetcher: async ({ campaignId }) => {
    if (!campaignId) {
      return null;
    }

    const { data } = await supabase.storage
      .from('campaigns')
      .getPublicUrl(campaignId);
    return `${data.publicUrl}?t=${Date.now()}`;
  },
});
