import type { AxiosError } from 'axios';
import type { Creator } from 'backend';
import { createQuery } from 'react-query-kit';

import { supabase } from '../../supabase-client';

type Response = Creator;
type Variables = { id: string };

export const useCreator = createQuery<Response, Variables, AxiosError>({
  queryKey: ['creator'],
  fetcher: async ({ id }) => {
    const { data, error } = await supabase
      .from('creators')
      .select('*')
      .eq('uid', id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Creator not found');

    return data;
  },
});
